import { CatalogSessionDto } from '../../types/shared-types';
import { BusinessInfo } from '@wix/bookings-uou-types';
import { getDayFromDateAsISO } from '../../mappers/date-mappers-helpers';
import {
  DurationMapper,
  DurationMapperOptions,
} from '@wix/bookings-uou-mappers';
import type { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import type { Event } from '@wix/ambassador-calendar-v3-event/types';

export interface SchedulingSectionViewModel {
  schedulingDaysViewModel?: SchedulingDayViewModel[];
  status: SchedulingSectionStatus;
  firstSessionDate?: string;
  lastSessionDate?: string;
  isBookable: boolean;
}

export interface SchedulingDayViewModel {
  date: string;
  dailySessions: DailySession[];
}

export interface DailySession {
  startTime: string;
  durationText: string;
  durationAriaLabel: string;
  staffName: string;
  locationName?: string;
}

export interface FormattedDailySession extends DailySession {
  startDate: string;
  year?: string;
  date: string;
}

// https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope
// eslint-disable-next-line no-shadow
export enum SchedulingSectionStatus {
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export const schedulingSectionViewModelFactory = ({
  useQueryEvents,
  catalogSessionsDto,
  serviceSessions,
  businessInfo,
  firstSessionStart,
  lastSessionEnd,
  viewTimezone,
  isCourse = false,
  isBookable,
  t,
  service,
}: {
  useQueryEvents?: boolean;
  serviceSessions?: Event[];
  catalogSessionsDto?: CatalogSessionDto[];
  businessInfo: BusinessInfo;
  firstSessionStart?: string;
  lastSessionEnd?: string;
  viewTimezone?: string;
  isCourse?: boolean;
  isBookable: boolean;
  t;
  service?: Service;
}): SchedulingSectionViewModel => {
  const sessions = useQueryEvents ? serviceSessions : catalogSessionsDto;

  if (!sessions || sessions.length === 0) {
    return { status: SchedulingSectionStatus.EMPTY, isBookable };
  }

  const dateTimeFormatter = new Intl.DateTimeFormat(
    businessInfo.dateRegionalSettingsLocale,
    {
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: viewTimezone,
    },
  );
  const dateFormatter = new Intl.DateTimeFormat(
    businessInfo.dateRegionalSettingsLocale,
    {
      weekday: 'long',
      day: 'numeric',
      month: 'short',
      timeZone: viewTimezone,
    },
  );
  const durationMapperOptions: DurationMapperOptions = {
    hourUnit: 'duration.units.hours',
    hourAriaUnit: 'duration.units.aria-hours',
    minuteUnit: 'duration.units.minutes',
    minuteAriaUnit: 'duration.units.aria-minutes',
  };
  const durationMapper = new DurationMapper(
    businessInfo.dateRegionalSettingsLocale!,
    durationMapperOptions,
    t,
  );

  let firstSessionDate, lastSessionDate;
  if (isCourse && firstSessionStart && lastSessionEnd) {
    const sessionRangeFormat = new Intl.DateTimeFormat(
      businessInfo.dateRegionalSettingsLocale,
      {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        timeZone: viewTimezone,
      },
    );
    firstSessionDate = sessionRangeFormat.format(new Date(firstSessionStart));
    lastSessionDate = sessionRangeFormat.format(new Date(lastSessionEnd));
  }

  const diffMinutes = (dt2, dt1) => {
    const diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.abs(Math.round(diff / 60));
  };

  // events api does not support multilingual so we take from services api.
  const staffNames = new Map<string, string>();
  const locationNames = new Map<string, string>();

  service?.staffMemberDetails?.staffMembers?.forEach((staff) => {
    staffNames.set(staff.staffMemberId!, staff.name!);
  });

  service?.locations?.forEach((location) => {
    if (location.business?.name) {
      locationNames.set(location.id!, location.business?.name!);
    }
  });

  const getStaffNameFromSession = (session: Event) => {
    const staffMemberId = session.resources?.[0]?.id;
    return (
      (service && staffMemberId && staffNames.get(staffMemberId)) ||
      session.resources?.[0]?.name
    );
  };

  const getLocationNameFromSession = (session: Event) => {
    const locationId = session.location?.id;
    return (
      (service && locationId && locationNames.get(locationId)) ||
      session.location?.name
    );
  };

  const formattedSessions: FormattedDailySession[] = sessions.map(
    (sessionDto) => {
      if (useQueryEvents) {
        const session = sessionDto as Event;
        const sessionStartDate = new Date(session.start?.utcDate!);
        const dateParts = dateTimeFormatter.formatToParts(sessionStartDate);
        const date = dateFormatter.format(sessionStartDate);
        const year = dateParts.find(({ type }) => type === 'year')?.value;
        const hour = dateParts.find(({ type }) => type === 'hour')?.value;
        const minute = dateParts.find(({ type }) => type === 'minute')?.value;
        const dayPeriod = dateParts.find(
          ({ type }) => type.toLocaleLowerCase() === 'dayperiod',
        )?.value;
        const startTime = `${hour}:${minute} ${dayPeriod ?? ''}`.trim();
        const durationInMinutes = diffMinutes(
          session.end?.utcDate,
          session.start?.utcDate,
        );

        return {
          startDate: session.start?.utcDate?.toISOString(),
          date,
          year,
          staffName: getStaffNameFromSession(session),
          startTime,
          durationText:
            durationMapper.durationTextFromMinutes(durationInMinutes),
          durationAriaLabel:
            durationMapper.durationAriaLabelFromMinutes(durationInMinutes),
          locationName: getLocationNameFromSession(session),
        };
      }

      const sessionStartDate = new Date(sessionDto.startDate);
      const dateParts = dateTimeFormatter.formatToParts(sessionStartDate);
      const date = dateFormatter.format(sessionStartDate);
      const year = dateParts.find(({ type }) => type === 'year')?.value;
      const hour = dateParts.find(({ type }) => type === 'hour')?.value;
      const minute = dateParts.find(({ type }) => type === 'minute')?.value;
      const dayPeriod = dateParts.find(
        ({ type }) => type.toLocaleLowerCase() === 'dayperiod',
      )?.value;
      const startTime = `${hour}:${minute} ${dayPeriod ?? ''}`.trim();

      return {
        startDate: sessionDto.startDate,
        date,
        year,
        staffName: sessionDto.staffName,
        startTime,
        durationText: durationMapper.durationTextFromMinutes(
          sessionDto.durationInMinutes,
        ),
        durationAriaLabel: durationMapper.durationAriaLabelFromMinutes(
          sessionDto.durationInMinutes,
        ),
        locationName: sessionDto.locationName,
      };
    },
  );

  return {
    schedulingDaysViewModel: formatDays(formattedSessions),
    status: SchedulingSectionStatus.SUCCESS,
    firstSessionDate,
    lastSessionDate,
    isBookable,
  };
};

function sortSessions(sessions: FormattedDailySession[]) {
  sessions.sort((a, b) => {
    return (
      a.startDate.localeCompare(b.startDate) ||
      (a.locationName || '').localeCompare(b.locationName || '') ||
      a.staffName.localeCompare(b.staffName)
    );
  });
}

function formatDays(
  formattedSessions: FormattedDailySession[],
): SchedulingDayViewModel[] {
  sortSessions(formattedSessions);
  const mappedDays: Record<string, SchedulingDayViewModel> = {};
  formattedSessions.forEach((session) => {
    const dayKey = `${session.date}-${session.year}`;
    mappedDays[dayKey] = mappedDays[dayKey] || initDay(session);
    mappedDays[dayKey].dailySessions.push({
      startTime: session.startTime,
      staffName: session.staffName,
      durationText: session.durationText,
      durationAriaLabel: session.durationAriaLabel,
      locationName: session.locationName,
    });
  });

  return Object.values(mappedDays);
}

const initDay = (session): SchedulingDayViewModel => {
  return {
    date: session.date,
    dailySessions: [],
  };
};

export const dummySchedulingViewModel = ({
  t,
  scheduleDays,
  businessInfo,
}: {
  t;
  scheduleDays: number;
  businessInfo: BusinessInfo;
}): SchedulingSectionViewModel => {
  const now = new Date(new Date().setUTCHours(10, 0, 0, 0));
  const staffName = t('dummy-data.staff-name');
  const locationName = t('dummy-data.location-name');
  const durationInMinutes = 45;
  let catalogSessionsDto: CatalogSessionDto[] = [];
  const placeholderSession: CatalogSessionDto = {
    startDate: now.toISOString(),
    endDate: now.toISOString(),
    staffName,
    durationInMinutes,
    locationName,
  };
  for (let i = 0; i < scheduleDays; i++) {
    catalogSessionsDto.push(placeholderSession);
  }

  catalogSessionsDto = catalogSessionsDto.map((value, index) => {
    return {
      ...value,
      startDate: getDayFromDateAsISO(now, index),
    };
  });
  return schedulingSectionViewModelFactory({
    catalogSessionsDto,
    businessInfo,
    t,
    isBookable: true,
  });
};
