import { ControllerFlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { queryServices } from '@wix/ambassador-bookings-services-v2-service/http';
import { queryEvents } from '@wix/ambassador-calendar-v3-event/http';
import {
  RequestedFields,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ServicesCatalogServer } from '@wix/ambassador-services-catalog-server/http';
import { ScheduleServer } from '@wix/ambassador-schedule-server/http';
import {
  ListSchedulesRequest,
  Schedule,
} from '@wix/ambassador-schedule-server/types';
import {
  BOOKINGS_APP_DEF_ID,
  getInstance,
  getServerBaseUrl,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const SCHEDULER_SERVER_URL = '_api/schedule-server';
export class BookingsApi {
  private httpClient: IHttpClient;
  private flowAPI: ControllerFlowAPI;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private scheduleServer: ReturnType<typeof ScheduleServer>;
  private authorization: string;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    const baseUrl = getServerBaseUrl({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });
    this.httpClient = flowAPI.httpClient;
    this.flowAPI = flowAPI;
    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.authorization = getInstance({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });
    this.scheduleServer = ScheduleServer(`${baseUrl}${SCHEDULER_SERVER_URL}`);
  }

  public async queryServicesBySlug({
    serviceSlug,
  }: {
    serviceSlug: string;
  }): Promise<Service> {
    const { isPreview } = this.flowAPI.environment;
    const filter = {
      hidden: { $eq: false },
      'supportedSlugs.name':
        serviceSlug || !isPreview ? serviceSlug : undefined,
    };
    const res = await this.httpClient.request(
      queryServices({
        conditionalFields: [RequestedFields.STAFF_MEMBER_DETAILS],
        query: {
          filter,
        },
      }),
    );
    return (res.data.services && res.data.services[0])!;
  }

  public async getBusinessInfo() {
    const businessInfoService = this.catalogServer.BusinessCatalog();
    return businessInfoService({
      Authorization: this.authorization,
    }).get({});
  }

  public async listSchedule(scheduleOwnerId: string): Promise<Schedule> {
    const listSchedulesRequest: ListSchedulesRequest = {
      scheduleOwnerIds: [scheduleOwnerId],
      includeTotalNumberOfParticipants: true,
    };
    const scheduleService = this.scheduleServer.Schedules();
    const schedulesResponse = await scheduleService({
      Authorization: this.authorization,
    }).list(listSchedulesRequest);
    return schedulesResponse?.schedules?.filter(
      (schedule) => schedule.status === 'CREATED',
    )[0]!;
  }

  async queryEvents({
    from,
    to,
    scheduleId,
    timezone,
  }: {
    from: Date;
    to: Date;
    scheduleId: string;
    timezone: string;
  }) {
    return this.httpClient.request(
      queryEvents({
        fromLocalDate: DateToLocalDateISOString(from, timezone),
        toLocalDate: DateToLocalDateISOString(to, timezone),
        query: {
          filter: {
            $and: [
              {
                appId: BOOKINGS_APP_DEF_ID,
              },
              {
                scheduleId,
              },
            ],
          },
        },
      }),
    );
  }
}

const DateToLocalDateISOString = (date: Date, timeZone: string) => {
  // locale sv formats the date as yyyy-mm-dd hh:mm:ss
  return date.toLocaleString('sv', { timeZone }).replace(' ', 'T');
};
